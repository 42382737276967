import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { LayoutsService } from '../services/layouts.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SurveyService } from 'src/app/admin/surveys/services/survey.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {

  menu: any;

  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

  constructor(public layout: LayoutsService, public shared: SharedService, public surveyService: SurveyService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnChanges() {
  }

  onOpen(menu: string, secondMenu?: string) {
    this.layout.activeMenu = menu;
    this.layout.activeSecondMenu = secondMenu;
    this.layout.mapEditor = false;
    this.layout.reportEditor = false;

  }

}
