import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  activeReport: any;

  constructor(private api: ApiService) { }

  getReports(surveyId: string, page: number, pageSize: number) {
    return new Promise((res, rej) => {
      this.api.reports.getReports(surveyId, page, pageSize).subscribe(resp => {
        res(resp);
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    })
  }
}
