import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LayoutsService } from './services/layouts.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../shared/services/api.service';
import { SharedService } from '../shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { RealmService } from '../shared/services/realm.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  constructor(public layout: LayoutsService, private cookie: CookieService, private api: ApiService, public shared: SharedService, public realmService: RealmService) { }

  ngOnInit() {
    const activeWorkspaceJSON = this.cookie.get('activeWorkspace');
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;
    if (activeWorkspace) {
      this.shared.activeWorkspace = activeWorkspace;
      this.api.organisation.getOrganisationById().subscribe(response => {
        this.shared.activeWorkspace = response;
        this.cookie.set('activeWorkspace', JSON.stringify(response), 30, '/', environment.cookieDomain);
      }, error => {
        this.api.errorhandler(error);
      })
    }

    if (this.cookie.get('MaplixToken')) {
      this.realmService.auth.checkApiKey(this.cookie.get('MaplixToken'));
    }

  }

  ngAfterViewInit() {
    document.body.classList.remove('authentication-bg');
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }


}
