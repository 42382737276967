import { FormArray, FormGroup } from '@angular/forms';

export function ValidateSelectAtLeastOne(fa: FormArray) {
  const selected = fa.controls.filter(x => x.get('selected').value);
  if (!selected.length) return { noneSelected : true };
  return null;
}

export function ValidateTransportModes(fg: FormGroup) {
  const val = fg.value;
  const oneSelected = Object.keys(val).filter(x => val[x]).length;
  if (!oneSelected) return { noneSelected : true };
  return null;
}