<!-- Topbar Start -->
<div class="navbar-custom" style="z-index: 900;">
  <ul class="list-unstyled topnav-menu float-right mb-0 mr-4">

    <li routerLinkActive="border-bottom-white mr-2" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link text-white" routerLink="/">Home</a>
    </li>

    <li class="dropdown notification-list" ngbDropdown>
      <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" ngbDropdownToggle id="profileDropdown"
        href="javascript: void(0);" role="button" aria-haspopup="false" aria-expanded="false">
        <img
          [src]="shared.userDetails?.user_profile?.data?.picture ? shared.userDetails?.user_profile?.data?.picture : 'assets/images/profile-empty.png'"
          alt="" class="img-border">
        <span class="pro-user-name ml-1">
          {{shared.userDetails?.name}}
          <i class="mdi mdi-chevron-down"></i>
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-dropdown" aria-labelledby="profileDropdown" ngbDropdownMenu>

        <!-- item-->
        <!-- <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="onGoToAccount()">
          <div class="d-flex align-items-center">
            <i class="remixicon-user-3-line mr-2"></i>
            <span>My Account</span>
          </div>
        </a>

        <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="onSwitchOrg()">
          <div class="d-flex align-items-center">
            <i class="mdi mdi-repeat mr-2"></i>
            <span>Organisation</span>
          </div>
        </a>

        <div class="dropdown-divider"></div> -->

        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logout()">
          <i class="remixicon-logout-box-line mr-2"></i>
          <span>Logout</span>
        </a>

      </div>
    </li>


  </ul>

  <!-- LOGO -->
  <div class="logo-box">
    <a routerLink="/" class="h-100">
      <span class="logo-lg d-flex align-items-start h-100 ml-4">
        <div class="d-flex align-items-center my-auto">
          <img src="assets/images/logo_white.png" alt="" height="30">
          <div class="h5 m-0 mb-1">
            <div class="badge ml-1 badge-white">Studio</div>
          </div>
        </div>
      </span>
    </a>
  </div>

  <ul class="list-unstyled topnav-menu topnav-menu-left m-0 h-100">

    <!-- <li *ngIf="layout.title">
      <a class="nav-link h5 my-0 font-weight-light ml-3" [innerHTML]="layout.title"></a>
    </li> -->
    <!-- 
    <li class="h-100 d-flex align-items-center ml-3">
      <img src="assets/images/logo2.png" alt="" height="25">
    </li> -->

    <!-- <li>
      <button class="d-lg-none button-menu-mobile waves-effect waves-light" (click)="toggleMobileMenu($event)">
        <i class="fe-menu"></i>
      </button>
    </li> -->

    <!-- <li class="dropdown d-none d-lg-block" ngbDropdown>
      <a class="nav-link dropdown-toggle waves-effect waves-light" href="javascript: void(0);" ngbDropdownToggle
        id="extraMenu" role="button" aria-haspopup="false" aria-expanded="false">
        Create New
        <i class="mdi mdi-chevron-down"></i>
      </a>
      <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="extraMenu">
        <a href="javascript:void(0);" class="dropdown-item" routerLink="/surveys/new">
          <i class="fe-file-text mr-2"></i>
          <span>Survey</span>
        </a>
      </div>
    </li> -->

  </ul>
</div>
<!-- end Topbar -->