import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedSortableDirective } from './directives/advanced-sortable.directive';
import { CountToDirective } from './directives/count-to.directive';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FormGroupFilterPipe, FilterPipe } from './pipes/filter.pipe';
import { FormGroupSortPipe, RandomOrderPipe, SortPipe } from './pipes/sort.pipe';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimscrollDirective } from './directives/slimscroll.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatingModule } from 'ng-starrating';
import { DragulaModule } from 'ng2-dragula';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [
    AdvancedSortableDirective,
    CountToDirective,
    SlimscrollDirective,
    PreloaderComponent,
    FormGroupFilterPipe,
    FilterPipe,
    FormGroupSortPipe,
    SortPipe,
    RandomOrderPipe
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RatingModule,
    DragulaModule.forRoot(),
    NgbModule,
    TranslateModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    AdvancedSortableDirective,
    CountToDirective,
    SlimscrollDirective,
    PreloaderComponent,
    FormGroupFilterPipe,
    FilterPipe,
    PerfectScrollbarModule,
    TranslateModule,
    BsDatepickerModule,
    NgSelectModule,
    NgbModule,
    DragulaModule,
    RatingModule,
    FormGroupSortPipe,
    SortPipe,
    RandomOrderPipe
  ]
})
export class SharedModule { }
