import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {

  isCondensed: boolean = false;

  analyticsSidebar: boolean;
  title: string;

  showFooter: boolean = true;
  showSidenav: boolean = false;
  showSecondSidenav: boolean = false;
  secondSidenavItems: any[] = [
    { label: 'General', id: 'general' },
    { label: 'Content', id: 'content' },
    // { label: 'Appearance', id: 'appearance' },
    // { label: 'Options', id: 'options' }
  ];
  secondSidenavTitle: string = 'Survey';
  activeMenu: string = 'survey';
  activeSecondMenu: string = 'general';

  reportEditor: boolean = false;
  mapEditor: boolean = false;

  constructor() { }

    /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('enlarged');
    this.isCondensed = !this.isCondensed;
  }
}
