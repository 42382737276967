import { Injectable } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ApexAnnotations, ApexDataLabels, ApexNonAxisChartSeries, ApexStroke, ApexLegend, ApexFill, ApexTooltip, ApexPlotOptions, ApexResponsive, ApexYAxis, ApexGrid, ApexStates, ApexTheme } from 'ng-apexcharts';
import { MapClsAnalytics } from 'src/app/shared/class/mapcls-analytics';
import { ApiService } from 'src/app/shared/services/api.service';
import { Text, Icon, Style, Stroke, Fill, Circle } from 'ol/style';
import { Tile as TileLayer, Vector as VectorLayer, Image as ImageLayer, Heatmap } from 'ol/layer.js';
import VectorSource from 'ol/source/Vector.js';
import { Cluster } from 'ol/source';
import { Geometry, Point, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon, GeometryCollection } from 'ol/geom';
import Feature from 'ol/Feature';

export type ChartOptions = {
  chart: ApexChart;
  annotations: ApexAnnotations;
  colors: string[];
  dataLabels: ApexDataLabels;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  stroke: ApexStroke;
  labels: string[];
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  grid: ApexGrid;
  states: ApexStates;
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  theme: ApexTheme;
};

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  map: MapClsAnalytics;
  activeSurvey;
  activeMap;
  layerStyles: any[] = [];
  mapLoading: boolean = false;
  legendHeight: string = 'auto';
  legendWidth: string = 'auto';

  chartOptions: Partial<ChartOptions> = {
    title: {
      align: 'left',
      style: {
        fontSize: '18px',
        fontFamily: 'Roboto, sans-serif',
      }
    },
    chart: {
      height: 450,
      type: 'bar',
      fontFamily: 'Roboto, sans-serif',
      toolbar: {
        show: true,
        tools: {
          download: '<i class="remixicon-download-line icon-lg" title="Download"></i>',
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    colors: ['#f48565', '#1a485b', '#15bec3', '#f7b84b', '#f1556c', ' #6559cc', '#1abc9c', '#2fbdec', '#f672a7', '#fd7e14'],
    legend: {
      offsetY: 0,
    },
    xaxis: {
      type: 'category',
      title: {
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500
        }
      },
      labels: {
        rotate: -30
      }
    },
    yaxis: {
      title: {
        text: 'Respondents',
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500
        }
      },
      forceNiceScale: true,
      min: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: '#f1f3fa'
    },
    tooltip: {
      y: {
        formatter(val) {
          return val + ' respondents';
        }
      }
    }
  }

  constructor(private api: ApiService) { }

  getMaps(surveyId: string, page: number, pageSize: number) {
    return new Promise((res, rej) => {
      this.api.map.getMaps(surveyId, page, pageSize).subscribe(resp => {
        res(resp);
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    })
  }

  onChangeStyleSource(source: string, interaction: any, fromClick?: boolean, map?: MapClsAnalytics) {
    if (fromClick && source === interaction.style.styleSource) {
      return interaction;
    }
    this.mapLoading = true;
    //remove the layer first
    let layer = map ? map.surveyLayers.find(x => x.get('pageid') == interaction.pageid) : this.map.surveyLayers.find(x => x.get('pageid') == interaction.pageid);
    const layerIndex = map ? map.surveyLayers.indexOf(layer) : this.map.surveyLayers.indexOf(layer);
    map ? map.surveyLayers.splice(layerIndex, 1) : this.map.surveyLayers.splice(layerIndex, 1);
    map ? map.map.removeLayer(layer) : this.map.map.removeLayer(layer);
    if (source === 'heatmap') {
      const hm = this.getHeatmap(interaction.drawOptions.geomType, layer, interaction);
      layer = hm.layer;
      interaction = hm.interaction;
    }
    else if (source === 'cluster') {
      const cluster = this.getClusters(layer, interaction);
      layer = cluster.layer;
      interaction = cluster.interaction;
    }
    else {
      layer = new VectorLayer({
        source: interaction.oldSource ? interaction.oldSource : layer.getSource(),
        style: this.map.getStyleFunctionFromStyles(interaction),
        zIndex: interaction.drawOptions.geomType === 'Point' ? 40002 : interaction.drawOptions.geomType === 'LineString' ? 40001 : 40000,
        base: false,
        pageid: layer.get('pageid')
      })
    }

    //add the new layer back to the map
    map ? map.surveyLayers.push(layer) : this.map.surveyLayers.push(layer);
    map ? map.map.addLayer(layer) : this.map.map.addLayer(layer);
    this.mapLoading = false;

    return interaction;
  }

  getHeatmap(geomType: string, layer: VectorLayer, interaction: any) {
    let source;
    if (interaction.oldSource) {
      source = interaction.oldSource;
    }
    else {
      source = interaction.oldSource = layer.getSource();
    }

    //remove previous conditions from styling
    if (interaction.style.styles.length > 1) {
      interaction.style = JSON.parse(JSON.stringify(this.map.defaultStyle));
    }

    switch (geomType) {
      case 'Point':
        break;
      case 'LineString':
        const features = source.getFeatures();
        source = new VectorSource();
        features.forEach(f => {
          const coords = f.getGeometry().getCoordinates();
          coords.forEach((c, index) => {
            if (index > 0) {
              const xLength = Math.abs(coords[index][0] - coords[index - 1][0]);
              const yLength = Math.abs(coords[index][1] - coords[index - 1][1]);
              const lengthSegment = Math.sqrt(Math.pow(xLength, 2) + Math.pow(yLength, 2));
              const amountPoints = Math.ceil(lengthSegment / 50);
              for (let i = 0; i < amountPoints; i++) {
                const xStep = coords[index - 1][0] + i * (xLength / amountPoints);
                const yStep = coords[index - 1][1] + i * (yLength / amountPoints);
                const point = new Point([xStep, yStep]);
                const obj = { ...f };
                obj.geometry = point;
                const feature = new Feature(obj);
                source.addFeature(feature);
              }
            }
          })
        })
        break;
      case 'Polygon':
        break;
    }

    const heatmap = new Heatmap({
      source: source,
      zIndex: 40000,
      base: false,
      pageid: layer.get('pageid'),
      blur: interaction.style.styles[0].style.circle.blurSize,
      radius: interaction.style.styles[0].style.circle.blurRadius
    })

    if (geomType === 'LineString') {
      heatmap.setRadius(1);
      heatmap.setBlur(3);
    }

    return {
      layer: heatmap,
      interaction: interaction
    };
  }

  getClusters(layer: VectorLayer, interaction: any) {
    let source = layer.getSource();
    if (!interaction.oldSource) {
      interaction.oldSource = source;
    }

    //remove previous conditions from styling
    if (interaction.style.styles.length > 1) {
      interaction.style = JSON.parse(JSON.stringify(this.map.defaultStyle));
    }

    const newSource = new Cluster({
      distance: interaction.style.clusterDistance,
      source: source,
    })

    const clusterImage = new Circle({
      radius: interaction.style.styles[0].style.circle.radius,
      fill: new Fill({
        color: interaction.style.styles[0].style.circle.fillColor
      }),
      stroke: new Stroke({
        color: interaction.style.styles[0].style.circle.strokeColor,
        width: interaction.style.styles[0].style.circle.strokeWidth
      })
    })

    const sizeFactor = interaction.style.styles[0].style.circle.sizeFactor;

    const newLayer = new VectorLayer({
      source: newSource,
      style: function (feature) {
        const size = feature.get('features').length;
        const style = new Style({
          image: new Circle({
            radius: size > 1 ? clusterImage.getRadius() * (Math.sqrt(size * sizeFactor)) : clusterImage.getRadius(),
            fill: clusterImage.getFill(),
            stroke: clusterImage.getStroke()
          }),
          text: new Text({
            text: size > 1 ? size.toString() : '',
            fill: new Fill({
              color: '#fff',
            })
          })
        });
        return style;
      },
      zIndex: 40002,
      base: false,
      pageid: layer.get('pageid')
    });

    return {
      layer: newLayer,
      interaction: interaction
    };
  }
}
