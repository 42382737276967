<footer class="footer border-top">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <div class="footer-links">
          {{currentYear}} © maplix.io
        </div>
      </div>
    </div>
  </div>
</footer>