import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ValidateSelectAtLeastOne } from 'src/app/shared/validators/form-validator';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';

export class SurveyResponse {

  form: FormGroup;

  constructor(public survey: any) {
    this.init();
  }

  init() {
    this.form = new FormGroup({
      survey: new FormControl(this.survey._id),
      pages: new FormArray([]),
      started: new FormControl(),
      completed: new FormControl()
    });

    const faPages = this.form.get('pages') as FormArray;

    this.survey.questions.pages.forEach(p => {
      const faQuestions = new FormArray([]);
      this.survey.questions.questions
      .filter(x => x.pageid == p.id)
      .sort((a, b) => {
        if (a.displayOrder > b.displayOrder) return 1;
        else if (a.displayOrder < b.displayOrder) return -1;
        else return 0;
      })
      .forEach(q => {
        if (p.type !== 'map') {
          const fg = new FormGroup({
            id: new FormControl(q.id),
            type: new FormControl(q.type),
            label: new FormControl(q.question.find(x => x.i18n == this.survey.general.defaultLanguage.code).value ? q.question.find(x => x.i18n == this.survey.general.defaultLanguage.code).value.replace(/<\/?[^>]+(>|$)/g, "") : ""),
            response: new FormControl(),
            subquestions: new FormArray([])
          });

          if (!q.subquestions.length) {
            if (q.options.required && q.options.textType === 'email' && [10,11].includes(q.type)) {
              fg.get('response').setValidators([Validators.required, Validators.email]);
            }
            else if (q.options.required) {
              fg.get('response').setValidators(Validators.required);
            }
            else if (q.options.textType === 'email') {
              fg.get('response').setValidators(Validators.email);
            }
          }

          const faSub = fg.get('subquestions') as FormArray;
          q.subquestions.forEach(sub => {
            let label;
            if (q.options.frontLabel && q.options.frontLabel.find(x => x.value === sub.subquestionid) && q.options.frontLabel.find(x => x.value === sub.subquestionid).label.find(x => x.i18n == this.survey.general.defaultLanguage.code).value) {
              label = q.options.frontLabel.find(x => x.value === sub.subquestionid).label.find(x => x.i18n == this.survey.general.defaultLanguage.code).value;
              if (q.options.backLabel && q.options.backLabel.find(x => x.value === sub.subquestionid) && q.options.backLabel.find(x => x.value === sub.subquestionid).label.find(x => x.i18n == this.survey.general.defaultLanguage.code).value) {
                label += ('...' + q.options.backLabel.find(x => x.value === sub.subquestionid).label.find(x => x.i18n == this.survey.general.defaultLanguage.code).value);
              }
            }
            else {
              label = sub.label.find(x => x.i18n == this.survey.general.defaultLanguage.code).value
            }

            const fgSub = new FormGroup({
              id: new FormControl(sub.subquestionid),
              response: new FormControl(),
              label: new FormControl(label)
            });

            if (q.options.required && q.options.textType === 'email' && [10,11].includes(q.type)) {
              fgSub.get('response').setValidators([Validators.required, Validators.email]);
            }
            else if (q.options.required) {
              fgSub.get('response').setValidators(Validators.required);
            }
            else if (q.options.textType === 'email') {
              fgSub.get('response').setValidators(Validators.email);
            }

            faSub.controls.push(fgSub);
          })
          faQuestions.controls.push(fg);
        }
      })

      const fgPage = new FormGroup({
        id: new FormControl(p.id),
        questions: faQuestions
      });

      if (p.type === 'map') {
        fgPage.addControl('mapInteractions', new FormArray([]));
        fgPage.addControl('mapSelections', new FormArray([]))
      }

      faPages.controls.push(fgPage);
    })
  }

  hasInvalidQuestions(form: FormGroup): boolean {
    let valid: boolean = true;
    const faQuestions = form.get('questions') as FormArray;

    faQuestions.controls.forEach(question => {
      const faSub = question.get('subquestions') ? question.get('subquestions') as FormArray : null;
      if (question.get('response') instanceof FormControl) {
        if (!faSub || !faSub.controls.length) {
          if (!question.get('response').valid) valid = false;
        }
        else if (faSub && faSub.controls.length) {
          faSub.controls.forEach((subq: FormGroup) => {
            if (subq.get('response') instanceof FormControl && !subq.get('response').valid) {
              valid = false;
            }
            else if (subq.get('response') instanceof FormArray) {
              const faResp = subq.get('response') as FormArray;
              if (!faResp.valid) {
                valid = false;
              }
              else {
                faResp.controls.forEach(ctrl => {
                  if (!ctrl.valid) valid = false;
                })
              }
            }
          })
        }
      }
      else if (question.get('response') instanceof FormArray) {
        const faResp = question.get('response') as FormArray;
        if (!faResp.valid) {
          valid = false;
        }
        else {
          faResp.controls.forEach(ctrl => {
            if (!ctrl.valid) valid = false;
          })
        }
      }
    })
    return valid;
  }

}