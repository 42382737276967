import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as Realm from 'realm-web';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class RealmService {

  private app: Realm.App;

  constructor(private router: Router, private cookie: CookieService, private shared: SharedService) {
    this.app = new Realm.App({ id: environment.stitchId });
  }

  public auth = {
    checkApiKey: async (token): Promise<void> => {
      const credentials = Realm.Credentials.apiKey(token);
      await this.app.logIn(credentials)
        .catch(error => {
          this.auth.logout();
        })
    },

    logout: () => {
      this.cookie.delete('userDetails', '/', environment.cookieDomain);
      this.cookie.delete('activeWorkspace', '/', environment.cookieDomain);
      this.cookie.delete('MaplixToken', '/', environment.cookieDomain);
      this.cookie.delete('MaplixTokenId', '/', environment.cookieDomain);
      this.shared.userDetails = undefined;
      this.cookie.set('redirectAfterLogin', environment.www, null, '/', environment.cookieDomain);
      location.href = `${environment.account}auth/login`;
    }
  }
}
