import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private shared: SharedService, private backendHandler: HttpBackend, private cookie: CookieService) { }

  mapboxToken = this.shared.mapboxToken;
  openroutetoken = this.shared.openrouteToken;

  public surveys = {
    getSurveys: (page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(`${environment.api}surveys?where={"organisation":"${this.shared.activeWorkspace._id}"}&max_results=${pageSize}&page=${page}&sort=[("_updated", -1)]`);
    },
    getSurveyById: (id: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${id}`);
    },
    saveSurvey: (surveyid: string, survey: any): Observable<any> => {
      if (!surveyid) {
        return this.http.post(`${environment.api}surveys`, survey)
      }
      else {
        return this.http.put(`${environment.api}surveys/${surveyid}`, survey)
      }
    },
    getDrafts: (max: number = 25): Observable<any> => {
      return this.http.get(`${environment.api}drafts?where={"author":"${this.shared.userDetails._id}"}&max_results=${max}&sort=[("_updated", -1)]`);
    },
    getDraft: (id: string): Observable<any> => {
      return this.http.get(`${environment.api}drafts/${id}`);
    },
    saveDraft: (draftid: string, survey: any): Observable<any> => {
      if (!draftid) {
        return this.http.post(`${environment.api}drafts`, survey)
      }
      else {
        return this.http.put(`${environment.api}drafts/${draftid}`, survey)
      }
    },
    deleteDraft: (draftid: string): Observable<any> => {
      return this.http.delete(`${environment.api}drafts/${draftid}`)
    },
    getDraftBySurveyId: (surveyid: string): Observable<any> => {
      return this.http.get(`${environment.api}drafts?where={"survey": "${surveyid}", "author": "${this.shared.userDetails._id}"}`);
    },
    deleteSurvey: (surveyid: string): Observable<any> => {
      return this.http.delete(`${environment.api}surveys/${surveyid}`);
    },
    getSurveyByUri: (uri: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys?where={"general.uri":"${uri}"}`);
    },
    getSurveyStats: (surveyid: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyid}/stats`);
    },
    sendReview: (surveyid: string, data: any, reviewid?: string): Observable<any> => {
      if (reviewid) {
        return this.http.put(`${environment.api}surveys/${surveyid}/reviews/${reviewid}`, data)
      }
      else {
        return this.http.post(`${environment.api}surveys/${surveyid}/reviews`, data)
      }
    }
  }

  public surveyResponses = {
    saveSurveyResponse: (surveyid: string, data: any, responseId: string): Observable<any> => {
      if (responseId) {
        return this.http.put(`${environment.api}surveys/${surveyid}/responses/${responseId}`, data);
      }
      else {
        return this.http.post(`${environment.api}surveys/${surveyid}/responses`, data);
      }
    },
    getSurveyResponses: (surveyid: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyid}/responses`);
    }
  }

  public analytics = {
    getChartByQuestions: (surveyid: string, data: any): Observable<any> => {
      return this.http.post(`${environment.api}surveys/${surveyid}/responses/metrics`, data);
    },
    export: (surveyid: string, contentType: string): Observable<HttpResponse<Blob>> => {
      const accessToken = this.cookie.get('MaplixToken');
      const hClient = new HttpClient(this.backendHandler);
      const headers = new HttpHeaders()
        .set('Content-Type', contentType)
        .set('Response-Type', 'blob')
        .set('Authorization', `Bearer ${accessToken}`);

      return hClient.post(`${environment.api}surveys/${surveyid}/responses/export`, {user: this.shared.userDetails._id}, { headers: headers, observe: 'response', responseType: 'blob' });
    },
    saveChart: (surveyId: string, data: any): Observable<any> => {
      return this.http.post(`${environment.api}surveys/${surveyId}/charts`, data);
    },
    getCharts: (surveyId: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyId}/charts`);
    },
    getChartById: (surveyId: string, chartId: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyId}/charts/${chartId}`);
    },
    getExports: (surveyId: string, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyId}/exports?max_results=?max_results=${pageSize}&page=${page}&sort=[("_created", -1)]&embedded={"user":1}`);
    }
  }

  public map = {
    searchAddress: (term: string): Observable<any> => {
      if (term) return this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${term}.json?access_token=${this.mapboxToken}&limit=10`);
      else return Observable.create();
    },
    getMaps: (surveyid: string, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyid}/maps?max_results=${pageSize}&page=${page}`);
    },
    getInteractions: (surveyid: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyid}/responses/interactions?aggregate={"$page":{},"$survey":"${surveyid}","$question": {}, "$subquestion": {}}`);
    },
    getInteractionsByPageId: (surveyid: string, pageid: number): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyid}/responses/interactions?aggregate={"$page":${pageid},"$survey":"${surveyid}","$question": {}, "$subquestion": {}}`);
    },
    saveMap: (surveyid: string, data: any, mapId?: string): Observable<any> => {
      if (!mapId) {
        return this.http.post(`${environment.api}surveys/${surveyid}/maps`, data);
      }
      else {
        return this.http.put(`${environment.api}surveys/${surveyid}/maps/${mapId}`, data);
      }
    },
    getMapById: (surveyid: string, mapid: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyid}/maps/${mapid}`);
    },
    deleteMap: (surveyid: string, mapid: string): Observable<any> => {
      return this.http.delete(`${environment.api}surveys/${surveyid}/maps/${mapid}`);
    }
  }

  public organisation = {
    getOrganisationById: (): Observable<any> => {
      return this.http.get(`${environment.api}organisations/${this.shared.activeWorkspace._id}`);
    },
    getOrganisationStats: (): Observable<any> => {
      return this.http.get(`${environment.api}organisations/${this.shared.activeWorkspace._id}/stats`)
    }
  }

  public reports = {
    getReports: (surveyId: string, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyId}/reports?max_results=${pageSize}&page=${page}`);
    },
    saveReport: (surveyId: string, data: any): Observable<any> => {
      if (!data._id) {
        return this.http.post(`${environment.api}surveys/${surveyId}/reports`, data);
      }
      else {
        return this.http.put(`${environment.api}surveys/${surveyId}/reports/${data._id}`, data);
      }
    },
    getReportById: (surveyId: string, reportId: string): Observable<any> => {
      return this.http.get(`${environment.api}surveys/${surveyId}/reports/${reportId}`);
    },
    deleteReport: (surveyId: string, reportId: string): Observable<any> => {
      return this.http.delete(`${environment.api}surveys/${surveyId}/reports/${reportId}`);
    }
  }

  public files = {
    upload: (file: File, surveyId: string, questionId: string): Observable<any> => {
      const data = new FormData();
      data.append('file', file);
      data.append('survey', surveyId);
      data.append('question', questionId);
      return this.http.post(`${environment.api}uploads`, data, {reportProgress: true, observe: "events"});
    },
    getFile: (fileId: string): Observable<any> => {
      return this.http.get(`${environment.api}uploads/${fileId}`);
    },
    removeFile: (fileId: string): Observable<any> => {
      return this.http.delete(`${environment.api}uploads/${fileId}`);
    }

  }

  public errorhandler(error) {
    if (error.status == 401) {
      this.shared.notification.warning("You don't have the rights to perform this operation", error.status);
    }
    else if (error.status == 403) {
      this.shared.notification.warning("You don't have the rights to perform this operation", error.status);
    }
    else if (error.status == 404) {
      this.shared.notification.warning("Data was not found", error.status);
    }
    else {
      this.shared.notification.danger("Something went wrong", error.status);
      console.error(error);
    }
  }
}
