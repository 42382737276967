import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Location } from "@angular/common"
import { SurveyService } from 'src/app/admin/surveys/services/survey.service';
import { LayoutsService } from '../services/layouts.service';
import { AnalyticsService } from 'src/app/admin/analytics/services/analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/services/api.service';
import { ReportService } from 'src/app/report/services/report.service';

@Component({
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss']
})
export class ContentWrapperComponent implements OnInit {

  maplixUrl: string = environment.www;
  deleteSurveySwal = {
    title: 'Delete survey',
    text: 'Are you sure you want to delete this survey? This action cannot be undone.',
    type: 'question',
    showCancelButton: false,
    confirmButtonText: 'Delete',
    showCloseButton: true
  }
  activeMapTitle: string;
  loading: boolean;

  constructor(public shared: SharedService, private translate: TranslateService, private _location: Location,
    public surveyService: SurveyService, public layout: LayoutsService, public analytics: AnalyticsService, private modalService: NgbModal,
    private api: ApiService, public report: ReportService) { }

  ngOnInit() {
  }

  get f() {
    return this.surveyService.surveyForm;
  }

  onOpenSurvey() {
    window.open(`${environment.www}${this.shared.selectedSurveyLanguage.code}/${this.shared.surveyButtons}`, '_blank');
  }

  onChangeLanguage() {
    this.translate.use(this.shared.selectedSurveyLanguage.code);
    if (this.surveyService.selectableMap) {
      this.surveyService.selectableMap.language = this.shared.selectedSurveyLanguage.code;
    }
  }

  onGoBack() {
    this._location.back();
  }

  async onSaveSurvey() {
    await this.surveyService.saveSurvey()
  }

  async onCopySurvey() {
    await this.surveyService.copySurvey()
  }

  async onPublishSurvey() {
    this.surveyService.surveyForm.get('published').setValue(true);
    await this.surveyService.saveSurvey(true);
    if (this.surveyService.survey) {
      this.surveyService.survey.published = true;
    }
  }

  async onTerminateSurvey() {
    this.surveyService.surveyForm.get('published').setValue(false);
    await this.surveyService.terminateSurvey();
    this.shared.activeBadge = null;
    if (this.surveyService.survey) {
      this.surveyService.survey.published = false;

    }
  }

  async onDeleteSurvey() {
    await this.surveyService.deleteSurvey();
  }

  onOpenPreview(mobile: boolean = false) {
    window.open(`${environment.www}${this.shared.selectedSurveyLanguage.code}/${mobile ? 'mobile-' : ''}preview/${this.f.get('general.uri').value}`, '_blank');
  }

  @ViewChild('mapTitle', { static: true }) mapTitleModal;
  onSaveMap() {
    if (this.analytics.activeMap) {
      const baseLayer = this.analytics.map.map.getLayers().getArray().find(l => l.get('base') && l.getVisible());
      const dataLayerIds = this.analytics.map.map.getLayers().getArray().filter(l => !l.get('base') && l.getVisible()).map(l => l.get('pageid'));
      let data = {
        zoom: this.analytics.map.map.getView().getZoom(),
        center: this.analytics.map.map.getView().getCenter(),
        title: this.analytics.activeMap.title,
        baseLayer: baseLayer.get('name'),
        dataLayers: this.analytics.layerStyles.filter(x => dataLayerIds.includes(x.pageid)),
        legend: {
          titles: this.analytics.activeSurvey.questions.pages.map(x => {
            return {
              pageid: x.id,
              value: x.name.find(x => x.i18n === this.analytics.activeSurvey.general.defaultLanguage.code).value
            }
          }),
          height: this.analytics.legendHeight,
          width: this.analytics.legendWidth
        }
      }
      data.dataLayers.forEach(l => {
        delete l.oldSource;
      })
      this.api.map.saveMap(this.surveyService.surveyId, data, this.analytics.activeMap._id).subscribe(response => {
        this.analytics.activeMap = { _id: response._id };
        this.loading = false;
        this.shared.notification.success('Map saved succesfully');
      }, error => {
        this.api.errorhandler(error);
        this.loading = false;
      })
    }
    else {
      const modal = this.modalService.open(this.mapTitleModal, { keyboard: false, backdrop: 'static' });
      modal.result.then(title => {
        const baseLayer = this.analytics.map.map.getLayers().getArray().find(l => l.get('base') && l.getVisible());
        const dataLayerIds = this.analytics.map.map.getLayers().getArray().filter(l => !l.get('base') && l.getVisible()).map(l => l.get('pageid'));

        let data = {
          zoom: this.analytics.map.map.getView().getZoom(),
          center: this.analytics.map.map.getView().getCenter(),
          title: title,
          baseLayer: baseLayer.get('name'),
          dataLayers: this.analytics.layerStyles.filter(x => dataLayerIds.includes(x.pageid)),
          legend: {
            titles: this.analytics.activeSurvey.questions.pages.map(x => {
              return {
                pageid: x.id,
                value: x.name.find(x => x.i18n === this.analytics.activeSurvey.general.defaultLanguage.code).value
              }
            }),
            height: this.analytics.legendHeight,
            width: this.analytics.legendWidth
          }
        }
        data.dataLayers.forEach(l => {
          delete l.oldSource;
        })
        this.loading = true;
        this.api.map.saveMap(this.surveyService.surveyId, data).subscribe(response => {
          this.loading = false;
          this.shared.notification.success('Map saved succesfully')
        }, error => {
          this.api.errorhandler(error);
          this.loading = false;
        })

        this.activeMapTitle = undefined;
      })
        .catch(() => {
          this.activeMapTitle = undefined;
        })
    }
  }

  onSaveReport(report?: any) {
    let data = report ? report : this.report.activeReport
    data.title = data.tempTitle ? data.tempTitle : data.title;
    data.subtitle = data.tempSubtitle ? data.tempSubtitle : data.subtitle;
    delete data.tempTitle;
    delete data.tempSubtitle;
    this.api.reports.saveReport(this.surveyService.surveyId, data).subscribe(response => {
      this.report.activeReport._id = response._id;
      this.loading = false;
      this.shared.notification.success(report && report.published ? 'Report published succesfully' : report && !report.published ? 'Report terminated successfully' : 'Report saved succesfully');
      this.report.activeReport.published = data.published;
    }, error => {
      this.api.errorhandler(error);
      this.loading = false;
    })
  }

  async onPublishReport() {
    const data = { ...this.report.activeReport };
    data.published = true;
    this.onSaveReport(data);
  }

  async onTerminateReport() {
    const data = { ...this.report.activeReport };
    data.published = false;
    this.onSaveReport(data);
  }

}
