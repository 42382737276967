import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  activeTitle;
  breadcrumbs;
  activeBadge;
  surveyButtons: boolean;
  userDetails;
  activeWorkspace;
  isoLangs;
  surveyLanguages;
  selectedSurveyLanguage;
  mapboxToken = "pk.eyJ1IjoiY2VkcmljdmVyc2x1eXMiLCJhIjoiY2s5azFwaTFvMDg5czNsbnNxY2ExbHgyeSJ9.W_Tk-JA9ujNEL55b_cf7Hw";
  openrouteToken = "5b3ce3597851110001cf6248f4aafdd0459642d58e6f99006d53df0b";
  openrouteBackupToken = "5b3ce3597851110001cf62488346b6a4901a419688184afc81208623";
  activeGeomId: string;
  activeSelectionId: string;

  constructor(private http: HttpClient, private toastr: ToastrService, public translate: TranslateService) { 
    this.http.get('assets/availablelanguages.json')
    .subscribe((response: any) => {
      this.isoLangs = response.map((lang: any) => {
        return {
          code: lang.code,
          name: lang.name,
          nativeName: lang.nativeName,
          display: lang.code !== 'en' ? `${lang.nativeName} (${lang.name})` : lang.name
        }
      });
    });
  }

  bsConfig = {
    adaptivePosition: true,
    containerClass: 'theme-default',
    isAnimated: true,
    rangeInputFormat: 'DD/MM/YYYY',
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false
  }

  notification = {
    success: (msg: string, title?: string) => {
      this.toastr.success(msg, title)
    },
    warning: (msg: string, title?: string) => {
      this.toastr.warning(msg, title)
    },
    info: (msg: string, title?: string) => {
      this.toastr.info(msg, title)
    },
    danger: (msg: string, title?: string) => {
      this.toastr.error(msg, title)
    },
  }

  randomIdGenerator() {
    const opts = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let result = '';
    for (let i = 0; i < 10; i++) {
      const index = Math.round(Math.random() * (opts.length-1));
      result += opts[index];
    }
    return result;
  }

  markFormGroupTouched (formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
  
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  };
  
}
