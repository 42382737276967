import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { BlankComponent } from './blank/blank.component';
import { ContainerComponent } from './container/container.component';
import { ContentWrapperComponent } from './content-wrapper/content-wrapper.component';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    BlankComponent,
    ContainerComponent,
    ContentWrapperComponent,
    CookieConsentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    SweetAlert2Module.forRoot()
  ],
  exports: [
    CookieConsentComponent,
    FooterComponent
  ]
})
export class LayoutsModule { }
