import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

	constructor(private shared: SharedService, private cookie: CookieService) { }

	canActivate() {
		let isAuthenticated: boolean;
		const authJSON = this.cookie.get('userDetails');
		const session = authJSON ? JSON.parse(authJSON) : null;
		const activeWorkspaceJSON = this.cookie.get('activeWorkspace');
		const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;

		if (!session || !activeWorkspace) {
			isAuthenticated = false;
			this.cookie.set('redirectAfterLogin', environment.www, null, '/', environment.cookieDomain);
			location.href = `${environment.account}auth/login`;
		}
		else {
			this.shared.userDetails = session;
			// this.shared.activeWorkspace = activeWorkspace;
			isAuthenticated = true;
		}

		return isAuthenticated;
	}
}