<div class="row no-gutters justify-content-between align-items-center bg-white title-wrap px-4 border-bottom"
  id="content-wrapper-header">
  <div class="d-flex align-items-center">
    <!-- <div class="maplix-icon-circle mr-2 bg-white" style="width: 40px;" *ngIf="shared.activeTitle?.icon">
      <div class="d-flex justify-content-center align-items-center h-100 h3 my-0">
        <i [class]="shared.activeTitle?.icon"></i>
      </div>
    </div> -->
    <div class="h3 font-weight-normal font-20 my-2">{{shared.activeTitle?.title}}</div>

    <span class="badge ml-2"
      *ngIf="shared.activeBadge && !shared.activeBadge?.routerLink && !shared.activeBadge?.onClick"
      [class.badge-light-info]="shared.activeBadge?.type == 'info'"
      [class.badge-light-success]="shared.activeBadge?.type == 'success'"
      [class.badge-light-warning]="shared.activeBadge?.type == 'warning'"
      [class.badge-light-danger]="shared.activeBadge?.type == 'danger'"
      [class.badge-light-primary]="shared.activeBadge?.type == 'primary'"
      [class.badge-light-dark]="shared.activeBadge?.type == 'dark'">

      <i [class]="'mr-1 ' + shared.activeBadge?.icon" *ngIf="shared.activeBadge?.icon"></i>
      {{shared.activeBadge?.value}}
    </span>

    <button class="btn btn-sm ml-3" *ngIf="shared.activeBadge && shared.activeBadge?.routerLink"
      [routerLink]="shared.activeBadge?.routerLink" [class.btn-info]="shared.activeBadge?.type == 'info'"
      [class.btn-success]="shared.activeBadge?.type == 'success'"
      [class.btn-warning]="shared.activeBadge?.type == 'warning'"
      [class.btn-accent]="shared.activeBadge?.type == 'danger'"
      [class.btn-primary]="shared.activeBadge?.type == 'primary'"
      [class.btn-primary]="shared.activeBadge?.type == 'primary-dark'"
      [class.btn-accent]="shared.activeBadge?.type == 'accent'">

      <i [class]="'mr-1 ' + shared.activeBadge?.icon" *ngIf="shared.activeBadge?.icon"></i>
      {{shared.activeBadge?.value}}
    </button>

    <button class="btn btn-sm ml-3" *ngIf="shared.activeBadge && shared.activeBadge?.onClick"
      (click)="shared.activeBadge?.onClick()" [class.btn-info]="shared.activeBadge?.type == 'info'"
      [class.btn-success]="shared.activeBadge?.type == 'success'"
      [class.btn-warning]="shared.activeBadge?.type == 'warning'"
      [class.btn-accent]="shared.activeBadge?.type == 'danger'"
      [class.btn-primary]="shared.activeBadge?.type == 'primary'"
      [class.btn-primary]="shared.activeBadge?.type == 'primary-dark'"
      [class.btn-accent]="shared.activeBadge?.type == 'accent'">

      <i [class]="'mr-1 ' + shared.activeBadge?.icon" *ngIf="shared.activeBadge?.icon"></i>
      {{shared.activeBadge?.value}}
    </button>

    <ng-select *ngIf="shared.selectedSurveyLanguage && shared.surveyLanguages?.length > 1"
      [(ngModel)]="shared.selectedSurveyLanguage" [multiple]="false" [items]="shared.surveyLanguages" class="ml-3"
      bindLabel="display" [clearable]="false" [searchable]="false" style="min-width: 150px;"
      (change)="onChangeLanguage()"></ng-select>
  </div>

  <div class="text-muted d-none d-lg-block" *ngIf="layout.showSidenav">

    <ng-container *ngIf="layout.showSidenav && layout.activeMenu === 'survey'">
      <div class="d-flex align-items-center">
        <span *ngIf="!surveyService.draftSaving && surveyService.draftSaved" class="d-flex align-items-center mr-2">
          <i class="remixicon-check-line mr-1"></i>
          <span>Draft saved at <strong>{{ surveyService.draftSaved | date: "HH:mm" }}</strong></span>
        </span>
        <span *ngIf="!surveyService.draftSaving && surveyService.draftError" class="d-flex align-items-center mr-2">
          <i class="remixicon-error-warning-line mr-1"></i>
          <span>Error saving draft...</span>
        </span>
        <span *ngIf="surveyService.draftSaving" class="d-flex align-items-center mr-2">
          <i class="spinner-grow spinner-grow-sm mr-1"></i>
          <span>Saving draft...</span>
        </span>
        <div ngbDropdown class="d-inline">
          <button class="btn btn-primary-light btn-sm mr-1"
            [disabled]="!surveyService.surveyId || surveyService.draftId || loading" [title]="'Preview'"
            ngbDropdownToggle>
            <i class="mdi mdi-logout mr-1"></i>Preview
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownAction">
            <button ngbDropdownItem (click)="onOpenPreview()">
              <div class="d-flex align-items-center">
                <i class="mdi mdi-laptop mr-1"></i> Desktop
              </div>
            </button>
            <!-- <button ngbDropdownItem (click)="onOpenPreview(true)">
              <div class="d-flex align-items-center">
                <i class="mdi mdi-cellphone-iphone mr-1"></i> Mobile
              </div>
            </button> -->
          </div>
        </div>
        <button class="btn btn-primary btn-sm mr-1" (click)="onSaveSurvey()" title="Save survey" [disabled]="loading || surveyService.surveyForm?.invalid">
          <i class="mdi mdi-content-save mr-1"></i>Save
        </button>
        <button class="btn btn-primary-dark btn-sm" (click)="onPublishSurvey()" title="Publish survey"
          [disabled]="loading" *ngIf="!surveyService.surveyForm?.get('published')?.value">
          <i class="mdi mdi-share mr-1"></i>Publish
        </button>
        <button class="btn btn-accent btn-sm" (click)="onTerminateSurvey()" title="Terminate survey"
          [disabled]="loading" *ngIf="surveyService.surveyForm?.get('published')?.value">
          <i class="mdi mdi-close-circle mr-1"></i>Terminate
        </button>
        <div ngbDropdown *ngIf="surveyService.survey">
          <button class="btn btn-white btn-sm ml-1" ngbDropdownToggle>
            <i class="mdi mdi-dots-horizontal"></i>
            <div ngbDropdownMenu aria-labelledby="dropdownAction">
              <button ngbDropdownItem (click)="onCopySurvey()">
                <div class="d-flex align-items-center">
                  <i class="remixicon-file-copy-line mr-1"></i> Duplicate survey
                </div>
              </button>
              <button ngbDropdownItem [swal]="deleteSurveySwal" (confirm)="onDeleteSurvey()">
                <div class="d-flex align-items-center">
                  <i class="remixicon-delete-bin-6-line mr-1"></i> Delete survey
                </div>
              </button>
            </div>
          </button>
        </div>
      </div>

      <div class="d-flex d-lg-none">
        <div class="mb-1">
          <button class="btn btn-primary btn-xs icon-btn mr-1" (click)="onSaveSurvey()" title="Save survey"
            [disabled]="loading || surveyService.surveyForm?.invalid">
            <i class="mdi mdi-content-save"></i>
          </button>
          <button class="btn btn-primary btn-xs icon-btn" (click)="onPublishSurvey()" title="Publish survey"
            [disabled]="loading" *ngIf="!surveyService.surveyForm?.get('published')?.value">
            <i class="mdi mdi-share"></i>
          </button>
          <button class="btn btn-accent btn-xs icon-btn" (click)="onTerminateSurvey()" title="Terminate survey"
            [disabled]="loading" *ngIf="surveyService.surveyForm?.get('published')?.value">
            <i class="mdi mdi-close-circle"></i>
          </button>
          <div ngbDropdown>
            <button class="btn btn-white btn-sm ml-1" ngbDropdownToggle>
              <i class="mdi mdi-dots-horizontal"></i>
              <div ngbDropdownMenu aria-labelledby="dropdownAction">
                <button ngbDropdownItem (click)="onCopySurvey()">
                  <div class="d-flex align-items-center">
                    <i class="remixicon-file-copy-line mr-1"></i> Duplicate survey
                  </div>
                </button>
                <button ngbDropdownItem [swal]="deleteSurveySwal" (confirm)="onDeleteSurvey()">
                  <div class="d-flex align-items-center">
                    <i class="remixicon-delete-bin-6-line mr-1"></i> Delete survey
                  </div>
                </button>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="layout.showSidenav && layout.activeMenu === 'analytics'">

    </ng-container>

    <ng-container *ngIf="layout.showSidenav && layout.activeMenu === 'maps' && layout.mapEditor">
      <div class="d-none d-lg-flex">
        <button class="btn btn-primary btn-sm mr-1" (click)="onSaveMap()" title="Save map">
          <i class="mdi mdi-content-save mr-1"></i>Save
        </button>
      </div>
      <div class="d-flex d-lg-none">
        <button class="btn btn-primary btn-xs icon-btn mr-1" (click)="onSaveMap()" title="Save map">
          <i class="mdi mdi-content-save"></i>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="layout.showSidenav && layout.activeMenu === 'reports' && layout.reportEditor">
      <div class="d-none d-lg-flex">
        <button class="btn btn-primary btn-sm mr-1" (click)="onSaveReport()" title="Save report">
          <i class="mdi mdi-content-save mr-1"></i>Save
        </button>
        <button class="btn btn-primary-dark btn-sm" (click)="onPublishReport()" title="Publish report"
          [disabled]="loading" *ngIf="!report.activeReport?.published">
          <i class="mdi mdi-share mr-1"></i>Publish
        </button>
        <button class="btn btn-accent btn-sm" (click)="onTerminateReport()" title="Terminate report"
          [disabled]="loading" *ngIf="report.activeReport?.published">
          <i class="mdi mdi-close-circle mr-1"></i>Terminate
        </button>
      </div>
      <div class="d-flex d-lg-none">
        <button class="btn btn-primary btn-xs icon-btn mr-1" (click)="onSaveReport()" title="Save report">
          <i class="mdi mdi-content-save"></i>
        </button>
        <button class="btn btn-primary-dark btn-xs icon-btn" (click)="onPublishReport()" title="Publish report"
          [disabled]="loading" *ngIf="!report.activeReport?.published">
          <i class="mdi mdi-share"></i>
        </button>
        <button class="btn btn-accent btn-xs icon-btn" (click)="onTerminateReport()" title="Terminate report"
          [disabled]="loading" *ngIf="report.activeReport?.published">
          <i class="mdi mdi-close-circle"></i>
        </button>
      </div>
    </ng-container>
  </div>

</div>


<ng-template #mapTitle let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Give your map a title</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="form-label">Title</label>
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="activeMapTitle">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(activeMapTitle)"
      [disabled]="!activeMapTitle">Save</button>
  </div>
</ng-template>