import { Injectable, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl, FormBuilder, Form } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as moment from 'moment';
import { SurveyResponse } from 'src/app/survey-response/class/survey-response-form';
import { TranslateService } from '@ngx-translate/core';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MapClsSelectable } from 'src/app/shared/class/mapcls-selectable';
import { ValidateTransportModes } from 'src/app/shared/validators/form-validator';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  loading;
  survey;
  draftSaving: boolean;
  draftSaved: Date;
  surveySaved: Date;
  draftError: boolean;
  draftId: string;
  surveyId: string;
  drafts;
  surveys;
  map;
  uriStatus;
  selectableMap: MapClsSelectable;
  responseForm: SurveyResponse;
  surveyForm;
  lastDraftSave: Date;
  publishedSurveyId;
  surveyPublished: Date;

  constructor(private api: ApiService, private shared: SharedService, private translate: TranslateService, private router: Router) {
    this.resetSurveyForm();
  }


  resetSurveyForm() {
    this.surveyForm = new FormGroup({
      organisation: new FormControl(),
      published: new FormControl(),
      general: new FormGroup({
        uri: new FormControl(),
        title: new FormArray([]),
        subtitle: new FormArray([]),
        languages: new FormControl(null, Validators.required),
        defaultLanguage: new FormControl(null, Validators.required),
        project: new FormControl(),
        period: new FormControl(),
        intro: new FormArray([]),
        privacy: new FormArray([]),
        privacyChecks: new FormArray([]),
        outro: new FormArray([]),
        status: new FormControl()
      }),
      questions: new FormGroup({
        pages: new FormArray([]),
        questions: new FormArray([]),
        mapInteractions: new FormArray([])
      }),
      options: new FormGroup({}),
      appearance: new FormGroup({
        wizardLocation: new FormControl('top'),
        wizardLayout: new FormControl('small')
      })
    });
  }

  initSurveyForm(survey?: any) {
    if (!survey) {
      //reset pages to empty formarray
      this.surveyForm.get('questions')['controls'].pages = new FormArray([]);
      const faPages = this.surveyForm.get('questions.pages') as FormArray;
      //add default Page 1
      const fg = this.createPage(1, 'map');

      fg.get('type').valueChanges.subscribe((val: string) => {
        this.pageTypeChange(1, val);
      })
      faPages.controls = [...faPages.controls, fg];

      this.pageTypeChange(1, 'map');
    }
    else {
      this.shared.surveyLanguages = survey.general.languages;
      this.shared.selectedSurveyLanguage = survey.general.defaultLanguage;
      this.translate.use(this.shared.selectedSurveyLanguage.code);

      if (survey.general.period && survey.general.period.length) {
        survey.general.period = survey.general.period.map(d => { return moment(d).toDate() });
      }
      // patch form
      this.surveyForm.patchValue(survey);

      // patch all form arrays separately unfortunately
      const faTitle = this.surveyForm.get('general.title') as FormArray;
      const faSubTitle = this.surveyForm.get('general.subtitle') as FormArray;
      const faIntro = this.surveyForm.get('general.intro') as FormArray;
      const faPrivacy = this.surveyForm.get('general.privacy') as FormArray;
      const faPrivacyChecks = this.surveyForm.get('general.privacyChecks') as FormArray;
      const faOutro = this.surveyForm.get('general.outro') as FormArray;

      survey.general.title.forEach(d => {
        faTitle.controls.push(new FormGroup({ i18n: new FormControl(d.i18n), value: new FormControl(d.value, Validators.maxLength(100)) }));
      });
      survey.general.subtitle.forEach(d => {
        faSubTitle.controls.push(new FormGroup({ i18n: new FormControl(d.i18n), value: new FormControl(d.value) }));
      });
      survey.general.intro.forEach(d => {
        faIntro.controls.push(new FormGroup({ i18n: new FormControl(d.i18n), value: new FormControl(d.value) }));
      });
      survey.general.privacy.forEach(d => {
        faPrivacy.controls.push(new FormGroup({ i18n: new FormControl(d.i18n), value: new FormControl(d.value) }));
      });
      survey.general.privacyChecks.forEach(d => {
        const fg = new FormGroup({
          value: new FormControl(d.value),
          label: new FormArray([])
        });
        d.label.forEach(l => {
          fg.get('label')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
        });
        faPrivacyChecks.controls.push(fg);
      });
      survey.general.outro.forEach(d => {
        faOutro.controls.push(new FormGroup({ i18n: new FormControl(d.i18n), value: new FormControl(d.value) }));
      });

      this.initPages(survey.questions.pages);

      if (survey.questions.mapInteractions) {
        this.initMapInteractions(survey.questions.mapInteractions);
      }

      if (survey.questions.questions) {
        this.initQuestions(survey.questions.questions);
      }

    }

    setTimeout(() => {
      this.getExistingSurveysByUri(this.surveyForm.get('general.uri').value);
      this.surveyForm.get('general.uri').valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(val => {
          this.getExistingSurveysByUri(val);
        })

      this.addSubscribers(this.surveyForm);

    }, 1000)

    this.initResponseForm();
  }

  initResponseForm() {
    this.responseForm = new SurveyResponse(this.surveyForm.getRawValue());
  }

  initPages(pages) {
    const faPage = this.surveyForm.get('questions.pages') as FormArray;
    pages.forEach(p => {
      const faName = new FormArray([]);
      p.name.forEach(n => {
        faName.controls.push(new FormGroup({ i18n: new FormControl(n.i18n), value: new FormControl(n.value) }));
      });
      const fgPage = this.createPage(p.id, p.type, faName);
      fgPage.patchValue(p);
      // fgPage.get('type').valueChanges.subscribe((val: string) => {
      //   this.pageTypeChange(p.pageid, val);
      // })
      faPage.controls = [...faPage.controls, fgPage];
    });
  }

  initQuestions(questions) {
    const faQuestion = this.surveyForm.get('questions.questions') as FormArray;
    questions.forEach(q => {
      const faQ = new FormArray([]);
      q.question.forEach(n => {
        faQ.controls.push(new FormGroup({ i18n: new FormControl(n.i18n), value: new FormControl(n.value) }));
      });

      const faHelptext = new FormArray([]);
      q.helptext.forEach(n => {
        faHelptext.controls.push(new FormGroup({ i18n: new FormControl(n.i18n), value: new FormControl(n.value) }));
      });

      const faFieldValues = new FormArray([]);
      q.fieldValues.forEach(f => {
        const fg = new FormGroup({
          value: new FormControl(f.value),
          label: new FormArray([])
        });
        f.label.forEach(l => {
          fg.get('label')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
        })
        faFieldValues.controls.push(fg);
      });

      const faSubquestions = new FormArray([]);
      q.subquestions.forEach(f => {
        const fg = new FormGroup({
          subquestionid: new FormControl(f.subquestionid),
          label: new FormArray([])
        });
        f.label.forEach(l => {
          fg.get('label')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
        })
        faSubquestions.controls.push(fg);
      });

      const faRelations = new FormArray([]);
      q.relations.forEach(r => {
        const fg = new FormGroup({
          type: new FormControl(r.type),
          order: new FormControl(r.order),
          questionid: new FormControl(r.questionid),
          subquestionid: new FormControl(r.subquestionid),
          categoryid: new FormControl(r.categoryid),
          transportMode: new FormControl(r.transportMode),
          value: new FormControl(r.value),
          operator: new FormControl(r.operator),
          groupOperator: new FormControl(r.groupOperator)
        });
        faRelations.controls.push(fg);
      })

      const faMin = new FormArray([]);
      const faMiddle = new FormArray([]);
      const faMax = new FormArray([]);
      const faFront = new FormArray([]);
      const faBack = new FormArray([]);

      if (q.options.minLabel) {
        q.options.minLabel.forEach(l => {
          faMin.controls.push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
        })
      }
      if (q.options.middleLabel) {
        q.options.middleLabel.forEach(l => {
          faMiddle.controls.push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
        })
      }
      if (q.options.maxLabel) {
        q.options.maxLabel.forEach(l => {
          faMax.controls.push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
        })
      }
      if (q.options.frontLabel) {
        q.options.frontLabel.forEach(f => {
          const fg = new FormGroup({
            value: new FormControl(f.value),
            label: new FormArray([])
          });
          f.label.forEach(l => {
            fg.get('label')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
          })
          faFront.controls.push(fg);
        })
      }
      if (q.options.backLabel) {
        q.options.backLabel.forEach(b => {
          const fg = new FormGroup({
            value: new FormControl(b.value),
            label: new FormArray([])
          });
          b.label.forEach(l => {
            fg.get('label')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
          })
          faBack.controls.push(fg);
        })
      }

      const fgQuestion = this.createQuestion(q.pageid);

      fgQuestion.patchValue(q);
      fgQuestion['controls'].question = faQ;
      fgQuestion['controls'].helptext = faHelptext;
      fgQuestion['controls'].fieldValues = faFieldValues;
      fgQuestion['controls'].subquestions = faSubquestions;
      fgQuestion['controls'].relations = faRelations;
      fgQuestion.get('options')['controls'].minLabel = faMin;
      fgQuestion.get('options')['controls'].maxLabel = faMax;
      fgQuestion.get('options')['controls'].middleLabel = faMiddle;
      fgQuestion.get('options')['controls'].frontLabel = faFront;
      fgQuestion.get('options')['controls'].backLabel = faBack;

      // question type subscriber
      fgQuestion.get('type').valueChanges.subscribe(val => {
        this.questionTypeChange(fgQuestion, val);
      });

      faQuestion.controls.push(fgQuestion);
    })
  }

  initMapInteractions(mapInteractions) {
    const faMap = this.surveyForm.get('questions.mapInteractions') as FormArray;
    mapInteractions.forEach(m => {
      const fgMap = this.createMapInteraction(m.pageid);
      const faCat = new FormArray([]);
      const faDescription = new FormArray([]);
      m.description.forEach(d => {
        faDescription.controls.push(new FormGroup({ i18n: new FormControl(d.i18n), value: new FormControl(d.value) }));
      });
      if (m.categories) {
        m.categories.forEach(f => {
          const fg = new FormGroup({
            value: new FormControl(f.value),
            style: new FormGroup({
              color: new FormControl(f.style ? f.style.color : '#8BDCC7'),
              icon: new FormControl(f.style ? f.style.icon : null)
            }),
            label: new FormArray([]),
            helptext: new FormArray([])
          });
          if (f.label) f.label.forEach(l => {
            fg.get('label')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
          })
          if (f.helptext) f.helptext.forEach(l => {
            fg.get('helptext')['controls'].push(new FormGroup({ i18n: new FormControl(l.i18n), value: new FormControl(l.value) }));
          })
          faCat.controls.push(fg);
        })
      }

      fgMap.patchValue(m);
      fgMap['controls'].categories = faCat;
      fgMap['controls'].description = faDescription;
      faMap.controls.push(fgMap);
    });
  }

  createPage(id: number, type: string, nameArray: FormArray = new FormArray([])) {
    const fg = new FormGroup({
      id: new FormControl(id),
      defaultName: new FormControl(`Page ${id}`),
      name: nameArray,
      type: new FormControl(type)
    });
    return fg;
  }

  createQuestion(pageid: number, fa?: FormArray) {
    const displayOrder = this.getHighestDisplayOrder(pageid, fa);
    const fg = new FormGroup({
      id: fa ? new FormControl(`P${pageid}Q${displayOrder ? displayOrder : '1'}`) : new FormControl(),
      pageid: new FormControl(pageid),
      displayOrder: new FormControl(displayOrder),
      question: new FormArray([]),
      helptext: new FormArray([]),
      type: new FormControl(2),
      fieldValues: new FormArray([]),
      subquestions: new FormArray([]),
      relations: new FormArray([]),
      options: new FormGroup({
        required: new FormControl(true),
        requiredTrue: new FormControl(),
        multiple: new FormControl(),
        className: new FormControl(null),
        minLabel: new FormArray([]),
        middleLabel: new FormArray([]),
        maxLabel: new FormArray([]),
        stars: new FormControl(),
        acceptedFiles: new FormControl(['*']),
        inlineText: new FormControl(),
        frontLabel: new FormArray([]),
        backLabel: new FormArray([]),
        otherOption: new FormControl(),
        dateType: new FormControl(),
        minDate: new FormControl(),
        maxDate: new FormControl(),
        textType: new FormControl('text'),
        textRows: new FormControl(3)
      })
    });

    return fg;
  }

  getHighestDisplayOrder(pageid: number, fa: FormArray) {
    if (fa) {
      const displayOrders = fa.controls.filter(x => x.get('pageid').value == pageid).map(x => x.get('displayOrder').value);
      if (!displayOrders.length) return 1;
      return Math.max(...displayOrders) + 1;
    }
    return null;  
  }

  addSubscribers(form: AbstractControl) {
    //Save draft after every change of a formcontrol
    if (form instanceof FormControl) {
      form.valueChanges
        .pipe(debounceTime(2000))
        .subscribe(val => {
          if (!this.lastDraftSave || moment(moment()).diff(moment(this.lastDraftSave), 'milliseconds') > 100) {
            this.lastDraftSave = new Date();
            this.saveDraft();
          }
        });
    }
    else if (form instanceof FormGroup) {
      for (let ctrl in form.controls) {
        this.addSubscribers(form.get(ctrl));
      }
    }
    else if (form instanceof FormArray) {
      form.controls.forEach(ctrl => {
        this.addSubscribers(ctrl);
      })
    }
  }

  questionTypeChange(question: FormGroup, type: number) {
    //empty the subquestions, fieldValues, textboxes and labels first
    question['controls'].subquestions = new FormArray([]);
    question['controls'].fieldValues = new FormArray([]);
    question.get('options')['controls'].frontLabel = new FormArray([]);
    question.get('options')['controls'].backLabel = new FormArray([]);
    question.get('options')['controls'].minLabel = new FormArray([]);
    question.get('options')['controls'].maxLabel = new FormArray([]);
    question.get('options')['controls'].middleLabel = new FormArray([]);

    //add default fieldValues or subquestions
    switch (type) {
      case 2:
      case 3:
      case 4:
        //add one empty option
        this.onAddOption(question, true);
        break;
      case 5:
        //add one empty subquestion
        this.onAddSubquestion(question, true);
        this.onAddRangeLabels(question, true);
        break;
      case 8:
        //add one empty option
        this.onAddOption(question, true);
        this.onAddRangeLabels(question, true);
        break;
      case 9:
        //add one empty subquestion
        this.onAddSubquestion(question, true);
        // set default amt stars to 5
        if (!question.get('options.stars').value) {
          question.get('options.stars').setValue(5);
        }
        break;
      case 10:
        //add one empty textbox front- and back label
        this.onAddTextbox(question, null, true);
        break;
      case 11:
        //add one empty textbox front- and back label
        this.onAddSubquestionTextbox(question, true);
        break;
      case 7:
        //add one empty subquestion
        this.onAddSubquestion(question, true);
        //add one empty option
        this.onAddOption(question, true);
        if (!question.get('options.multiple').value) {
          question.get('options.multiple').setValue(false);
        }
        break;
      default:
        break;
    }

    this.addSubscribers(this.surveyForm.get('questions'));
  }

  onAddOption(question: FormGroup, fromTypeChange?: boolean) {
    const fieldValues = question.get('fieldValues') as FormArray;
    const fg = new FormGroup({
      value: new FormControl('OPT' + this.shared.randomIdGenerator()),
      label: new FormArray([])
    });

    const labels = fg.get('label') as FormArray;
    this.shared.surveyLanguages.forEach(lang => {
      labels.controls.push(new FormGroup({
        i18n: new FormControl(lang.code),
        value: new FormControl()
      }))
    })
    if (!fromTypeChange) {
      this.addSubscribers(fg);
    }

    fieldValues.controls.push(fg);
  }

  onAddSubquestion(question: FormGroup, fromTypeChange?: boolean) {
    const subquestions = question.get('subquestions') as FormArray;
    const fg = new FormGroup({
      subquestionid: new FormControl('SUB' + this.shared.randomIdGenerator()),
      label: new FormArray([])
    });

    const labels = fg.get('label') as FormArray;
    this.shared.surveyLanguages.forEach(lang => {
      labels.controls.push(new FormGroup({
        i18n: new FormControl(lang.code),
        value: new FormControl()
      }))
    })

    subquestions.controls.push(fg);
    if (!fromTypeChange) {
      this.addSubscribers(fg);
    }
  }
  onAddSubquestionTextbox(question: FormGroup, fromTypeChange?: boolean) {
    const subquestions = question.get('subquestions') as FormArray;
    const fg = new FormGroup({
      subquestionid: new FormControl('SUB' + this.shared.randomIdGenerator()),
      label: new FormArray([])
    });

    const labels = fg.get('label') as FormArray;
    this.shared.surveyLanguages.forEach(lang => {
      labels.controls.push(new FormGroup({
        i18n: new FormControl(lang.code),
        value: new FormControl()
      }))
    })

    if (!fromTypeChange) {
      this.addSubscribers(fg);
    }
    subquestions.controls.push(fg);
    this.onAddTextbox(question, fg.get('subquestionid').value);
  }

  onAddTextbox(question: FormGroup, subquestionid?: string, fromTypeChange?: boolean) {
    const frontLabel = question.get('options.frontLabel') as FormArray;
    const backLabel = question.get('options.backLabel') as FormArray;
    for (let i = 0; i <= 1; i++) {
      const fg = new FormGroup({
        value: new FormControl(subquestionid ? subquestionid : this.shared.randomIdGenerator()),
        label: new FormArray([])
      });

      const labels = fg.get('label') as FormArray;
      this.shared.surveyLanguages.forEach(lang => {
        labels.controls.push(new FormGroup({
          i18n: new FormControl(lang.code),
          value: new FormControl()
        }))
      })

      if (!fromTypeChange) {
        this.addSubscribers(fg);
      }

      if (i == 0) frontLabel.controls.push(fg);
      else if (i == 1) backLabel.controls.push(fg);
    }
  }

  onAddRangeLabels(question: FormGroup, fromTypeChange?: boolean) {
    this.shared.surveyLanguages.forEach(lang => {
      question.get('options.minLabel')['controls'].push(new FormGroup({
        i18n: new FormControl(lang.code),
        value: new FormControl()
      }));

      question.get('options.middleLabel')['controls'].push(new FormGroup({
        i18n: new FormControl(lang.code),
        value: new FormControl()
      }));

      question.get('options.maxLabel')['controls'].push(new FormGroup({
        i18n: new FormControl(lang.code),
        value: new FormControl()
      }));

      if (!fromTypeChange) {
        this.addSubscribers(question);
      }
    })
  }

  pageTypeChange(pageid: number, type: string) {
    const fa = this.surveyForm.get('questions.mapInteractions') as FormArray;
    if (type == 'map') {
      const fg = this.createMapInteraction(pageid);
      this.addSubscribers(fg);
      fa.controls = [...fa.controls, fg];
    }
  }

  createMapInteraction(pageid: number = 1) {
    const fg = new FormGroup({
      id: new FormControl(pageid),
      pageid: new FormControl(pageid),
      description: new FormArray([]),
      interactionType: new FormControl('draw'),
      defaultBaseLayer: new FormControl('mapbox-streets'),
      center: new FormControl([0, 0]),
      zoom: new FormControl(2),
      categories: new FormArray([]),
      drawOptions: new FormGroup({
        geomType: new FormControl('Point'),
        polygonType: new FormControl('regular'),
        lineType: new FormControl('regular'),
        required: new FormControl(true),
        multiple: new FormControl(true),
        multiplePerCategory: new FormControl(),
        categoriesRequired: new FormControl(),
        transportModes: new FormGroup({
          'CAR': new FormControl(true),
          'MOTOR': new FormControl(true),
          'TRAIN': new FormControl(true),
          'PUBLIC': new FormControl(true),
          'SCOOTER': new FormControl(true),
          'BIKE': new FormControl(true),
          'FOOT': new FormControl(true),
          'OTHER': new FormControl(true)
        }, ValidateTransportModes)
      }),
      selectOptions: new FormGroup({
        multiple: new FormControl(true),
        features: new FormControl([])
      })
    });

    if (this.shared.surveyLanguages) {
      this.shared.surveyLanguages.forEach(lang => {
        fg.get('description')['controls'].push(new FormGroup({
          i18n: new FormControl(lang.code),
          value: new FormControl()
        }))
      });
    }

    return fg;
  }

  getDrafts(max?: number) {
    return new Promise((res, rej) => {
      this.api.surveys.getDrafts(max).subscribe(resp => {
        this.drafts = resp._items;
        res(resp);
      }, error => {
        this.api.errorhandler(error);
        res();
      })
    })
  }

  getSurvey(id: string) {
    return new Promise((res, rej) => {
      this.api.surveys.getSurveyById(id).subscribe(resp => {
        res(resp);
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    });
  }

  getDraft(id: string) {
    return new Promise((res, rej) => {
      this.api.surveys.getDraft(id).subscribe(resp => {
        res(resp);
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    });
  }

  getDraftBySurveyId(surveyid: string) {
    return new Promise((res, rej) => {
      this.api.surveys.getDraftBySurveyId(surveyid).subscribe(resp => {
        res(resp._items.find(x => x.survey === surveyid));
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    });
  }

  getSurveys(page: number, pageSize: number) {
    return new Promise<any>((res, rej) => {
      this.api.surveys.getSurveys(page, pageSize).subscribe(resp => {
        res(resp);
      }, error => {
        this.api.errorhandler(error);
        res();
      })
    })
  }

  saveDraft() {
    this.initResponseForm();
    this.draftSaving = true;
    this.surveyForm.get('organisation').setValue(this.shared.activeWorkspace._id, { emitEvent: false });

    const data: any = {
      author: this.shared.userDetails._id,
      content: this.surveyForm.getRawValue()
    }

    if (this.surveyId) {
      data.survey = this.surveyId;
    }

    if (!this.surveySaved || moment().diff(moment(this.surveySaved), 'milliseconds') > 2000) {
      this.api.surveys.saveDraft(this.draftId, data).subscribe((resp) => {
        this.draftId = resp._id;
        this.draftSaved = new Date();
        this.draftSaving = false;
        this.draftError = false;
      }, error => {
        this.draftSaving = false;
        this.draftSaved = undefined;
        this.draftError = true;
      });
    }
    else {
      this.draftSaving = false;
    }
  }

  saveSurvey(publish?: boolean) {
    return new Promise((res, rej) => {
      this.surveyForm.get('organisation').setValue(this.shared.activeWorkspace._id);
      const data = this.surveyForm.getRawValue();
      this.api.surveys.saveSurvey(this.surveyId, data).subscribe(resp => {
        this.surveyId = resp._id;
        this.shared.notification.success(publish ? 'Survey published successfully' : 'Survey saved successfully');
        this.surveySaved = new Date();
        if (this.draftId) {
          this.api.surveys.deleteDraft(this.draftId).subscribe(response => {
            this.draftId = undefined;
          })
        }
        this.router.navigate(['/surveys/details', this.surveyId]);
        res();
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    })
  }

  copySurvey() {
    return new Promise((res, rej) => {
      const data = this.surveyForm.getRawValue();
      data.general.uri = null;
      data.general.title.forEach(lang => {
        lang.value = "COPY - " + lang.value
      });
      data.published = null;
      this.api.surveys.saveSurvey(null, data).subscribe(resp => {
        this.shared.notification.success('Survey copied successfully');
        this.router.navigate(['/']);
        res();
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    })
  }

  terminateSurvey() {
    return new Promise((res, rej) => {
      const data = this.surveyForm.getRawValue();
      this.api.surveys.saveSurvey(this.surveyId, data).subscribe(resp => {
        this.surveyId = resp._id;
        this.shared.notification.success('Survey terminated successfully');
        this.surveySaved = new Date();
        if (this.draftId) {
          this.api.surveys.deleteDraft(this.draftId).subscribe(response => {
            this.draftId = undefined;
          })
        }
        res();
      }, error => {
        this.api.errorhandler(error);
        rej();
      })
    })
  }

  deleteSurvey() {
    return new Promise((res, rej) => {
      this.api.surveys.deleteSurvey(this.surveyId).subscribe(resp => {
        if (this.draftId) {
          this.api.surveys.deleteDraft(this.draftId).subscribe(resp => {
            this.shared.notification.success('Survey deleted successfully');
            this.router.navigate(['/']);
            res();
          })
        }
        else {
          this.shared.notification.success('Survey deleted successfully');
          this.router.navigate(['/']);
          res();
        }
      }, error => {
        this.api.errorhandler(error);
        this.router.navigate(['/']);
        rej();
      })
    })
  }

  getExistingSurveysByUri(uri) {
    if (!new RegExp("^[a-zA-Z0-9]{6,40}$").test(uri)) {
      this.uriStatus = "wrongChars";
      this.surveyForm.get('general.uri').setErrors({ 'wrongChars': true });
    }
    else {
      this.uriStatus = 'loading';
      this.api.surveys.getSurveyByUri(uri).subscribe(response => {
        if (response._items.length && response._items[0]._id != this.surveyId) {
          this.uriStatus = "notUnique";
          this.surveyForm.get('general.uri').setErrors({ 'notUnique': true });
        }
        else {
          this.uriStatus = "success";
          this.surveyForm.get('general.uri').setErrors(null);
        }
      })
    }
  }
}
