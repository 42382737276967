export const environment = {
  env: 'STAGING',
  cookieDomain: 'maplix.io',
  production: false,
  www: 'https://staging.app.maplix.io/',
  account: 'https://staging.account.maplix.io/',
  api: `https://staging.api.maplix.io/`,
  stitchId: 'maplix-account-staging-gimhp',
  redirect: 'staging.app.maplix.io',
  version: require('../../package.json').version
};