<div id="wrapper">

    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()"
        (mobileMenuButtonClicked)="layout.onToggleMobileMenu()">
    </app-topbar>

    <app-content-wrapper></app-content-wrapper>

    <app-sidebar *ngIf="layout.showSidenav"></app-sidebar>

    <div class="content-page" [class.maplix-show-sidebar]="layout.showSidenav">
        <div [class]="'container-fluid px-md-1 margin-topbar'">
            <div [class]="'content'">
                <router-outlet></router-outlet>
            </div>
        </div>

        <app-footer *ngIf="layout.showFooter"></app-footer>
    </div>
</div>

<!-- <app-rightsidebar></app-rightsidebar> -->