import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  cookiesNotConsent: boolean;

  constructor(private cookie: CookieService) { }

  ngOnInit() {
    const consent = this.cookie.get('maplixCookieConsent');
    this.cookiesNotConsent = !consent;
  }

  onConsentCookies() {
    this.cookie.set('maplixCookieConsent', "true", 365, '/', environment.cookieDomain);
    this.cookiesNotConsent = false;
  }

}
