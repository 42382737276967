import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LayoutComponent } from './layouts/layout.component';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  { path: '', component: LayoutComponent, loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard] },
  { path: 'error', loadChildren: './error/error.module#ErrorModule' },
  { path: 'reports', loadChildren: './report/report.module#ReportModule' },
  { path: ':lang', loadChildren: './survey-response/survey-response.module#SurveyResponseModule' },
  { path: '**', redirectTo: 'error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
