<div class="maplix-sidebar border-right">
	<div id="sidebar-menu" class="d-flex h-100 w-100 flex-column align-items-center justify-content-between">
		<ul class="metismenu w-100">
			<li>
				<a class="waves-effect clickable" [class.route-active]="layout.activeMenu === 'survey'"
					(click)="onOpen('survey', 'general')">
					<i class="remixicon-file-list-2-line"></i>
					<span>Survey</span>
				</a>
				<ul class="nav-second-level collapse">
					<li (click)="onOpen('survey', 'general')">
						<a class="clickable side-nav-link-ref"
							[class.route-active]="layout.activeMenu === 'survey' && layout.activeSecondMenu === 'general'">General</a>
					</li>
					<li (click)="onOpen('survey', 'content')">
						<a class="clickable side-nav-link-ref"
							[class.route-active]="layout.activeMenu === 'survey' && layout.activeSecondMenu === 'content'">Content</a>
					</li>
					<!-- <li (click)="onOpen('survey', 'appearance')">
						<a class="clickable side-nav-link-ref"
							[class.route-active]="layout.activeMenu === 'survey' && layout.activeSecondMenu === 'appearance'">Appearance</a>
					</li> -->
				</ul>
			</li>
			<li>
				<a class="waves-effect clickable" [class.route-active]="layout.activeMenu === 'analytics'"
					[class.disabled]="!surveyService.survey"
					(click)="surveyService.survey ? onOpen('analytics', 'overview') : null">
					<i class="remixicon-bar-chart-fill"></i>
					<span>Analytics</span>
				</a>
				<ul class="nav-second-level collapse">
					<li (click)="surveyService.survey ? onOpen('analytics', 'overview') : null">
						<a class="clickable side-nav-link-ref" [class.disabled]="!surveyService.survey"
							[class.route-active]="layout.activeMenu === 'analytics' && layout.activeSecondMenu === 'overview'">Overview</a>
					</li>
					<li (click)="surveyService.survey ? onOpen('analytics', 'exports') : null">
						<a class="clickable side-nav-link-ref" [class.disabled]="!surveyService.survey"
							[class.route-active]="layout.activeMenu === 'analytics' && layout.activeSecondMenu === 'exports'">Exports</a>
					</li>
				</ul>
			</li>
			<li>
				<a class="waves-effect clickable" [class.route-active]="layout.activeMenu === 'maps'"
					(click)="surveyService.survey ? onOpen('maps') : null" [class.disabled]="!surveyService.survey">
					<i class="remixicon-pin-distance-fill"></i>
					<span>Maps</span>
				</a>
			</li>
			<li>
				<a class="waves-effect clickable" [class.route-active]="layout.activeMenu === 'reports'"
					(click)="surveyService.survey ? onOpen('reports') : null" [class.disabled]="!surveyService.survey">
					<i class="remixicon-file-chart-line"></i>
					<span>Reports</span>
				</a>
			</li>
		</ul>
	</div>
</div>